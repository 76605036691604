import React, { useRef } from 'react';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home, { HomeHandles } from "./components/Home";
import Logo from './assets/images/logo.png';
import './App.scss';

// Import MUI components
import AppBar from '@mui/material/AppBar';
import Container from '@mui/material/Container';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';


function App({isLoggedIn, user}: any)  {

  const homeRef = useRef<HomeHandles>(null);

  return (
  <Router>
    <AppBar className='navi-bar' position="static">
      <Container maxWidth="xl" className='main_app_container'>
        <Toolbar disableGutters>
          <Typography
            variant="h6"
            noWrap
            component="a"
            href="/"
            sx={{
              mr: 2,
              display: { xs: 'none', md: 'flex' },
              fontFamily: 'monospace',
              fontWeight: 700,
              letterSpacing: '.3rem',
              color: 'inherit',
              textDecoration: 'none',
            }}
          >
            <img className='logo_img' src={Logo} alt='logo'/>
          </Typography>

          <Typography
            variant="h5"
            noWrap
            component="a"
            href="/"
            sx={{
              mr: 2,
              display: { xs: 'flex', md: 'none' },
              flexGrow: 1,
              fontFamily: 'monospace',
              fontWeight: 700,
              letterSpacing: '.3rem',
              color: 'inherit',
              textDecoration: 'none',
            }}
          >
            <img className='logo_img' src={Logo} alt='logo'/>
          </Typography>
        </Toolbar>
      </Container>
    </AppBar>

      <Routes>
        <Route path="/" element={<Home ref={homeRef} isLoggedIn={isLoggedIn} user={user} />} />
      </Routes>
  </Router>
  );
}

export default  App;